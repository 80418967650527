@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  background: var(--grey);
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: var(--sectionPaddingSmall);
  padding-right: var(--sectionPaddingSmall);
  height: calc(100vh - (2 * var(--navBarHeight)));
  overflow: hidden;
  background-image: url('/static/images/1080_1080.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;

  @include for-mobile-screens {
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: top right;
    flex-direction: column-reverse;
  }
}

.logo {
  position: fixed;
  top: 5%;

  svg {
    cursor: pointer;
    width: auto;
    height: 96px;
    position: relative;

    path {
      fill: var(--navIconColor);
      transition: fill 0.15s ease-in;
    }
  }

  @include for-mobile-screens {
    svg {
      width: auto;
      height: 39px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  z-index: 99;

  @include for-mobile-screens {
    height: 100vh;
    margin-bottom: 50px;
  }
}

.info {
  position: relative;
  top: 30%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  @include for-mobile-screens {
    padding: 0px;
    top: 0;
    margin-top: auto;
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
}

.columnPadding {
  @include for-mobile-screens {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.button {
  width: fit-content;

  @include for-mobile-screens {
    width: 100%;
  }
}
